<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 mb-2 mb-md-0">
          <ul class="list mt-xl-5 mb-5 mb-xl-0">
            <li
              class="list-item"
              :class="{ active: selectedCategory === '全部' }"
            >
              <router-link
                class="list-item-link"
                to="/productList/all"
                @click="clickCategory('全部')"
              >
                <h6 class="h6">所有商品</h6>
                <span class="list-item-badge">{{ counterItems("全部") }}</span>
              </router-link>
            </li>
            <template v-for="category in categories" :key="category.id">
              <li
                class="list-item"
                :class="{
                  active: selectedCategory === category.name,
                }"
              >
                <router-link
                  class="list-item-link"
                  :to="`/productList/${category.path}`"
                  @click="clickCategory(category.name)"
                  ><h6 class="h6">
                    {{ category.name }}
                  </h6>
                  <span class="list-item-badge">{{
                    counterItems(category.name)
                  }}</span></router-link
                >
              </li>
            </template>
          </ul>
        </div>
        <div class="col-12 col-md-8 col-lg-9">
          <div class="container-fluid">
            <h3 class="h3 text-center">商品列表</h3>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <select v-model="selectedBrand" class="form-select mx-0">
                <option value="全部" selected>廠牌機種分類</option>
                <template v-for="item in brand" :key="item">
                  <option :value="item">{{ item }}</option>
                </template>
              </select>
              <h6 class="h6 mb-0 mr-1" style="letter-spacing: 0.25em">
                共有<span class="text-danger">{{
                  serchData.productsLength
                }}</span
                >件商品
              </h6>
            </div>
            <div class="row">
              <div
                class="col-12 col-sm-6 col-lg-4 mb-5"
                v-for="item in serchData.products"
                :key="item.id"
              >
                <Card :data="item" />
              </div>
            </div>
            <Pagination
              :pagination="serchData.pagination"
              @changePage="changePage"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onMounted, computed, inject } from "vue";
import { fetchProducts } from "connection/noAuth/productDB";
import { fetchCategories } from "connection/noAuth/categoryDB";
import Card from "components/front/Card";
import Pagination from "components/common/Pagination";
import filter from "helpers/filterData";
export default {
  name: "productList",
  components: {
    Card,
    Pagination,
  },
  setup() {
    const mitt = inject("mitt");
    const selectedCategory = ref("全部");
    //categories
    const categories = ref([]);
    const getAllCategories = async () => {
      try {
        categories.value = await fetchCategories();
      } catch (error) {
        console.log(error);
      }
    };
    //products
    const products = ref([]);
    const getAllProducts = async () => {
      try {
        mitt.emit("loading", true);
        products.value = await fetchProducts();
        mitt.emit("loading", false);
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      getAllCategories();
      getAllProducts();
    });
    const counterItems = computed(() => {
      return (selectedCategory) => {
        if (selectedCategory !== "全部") {
          return products.value.filter((item) => {
            return item.category.match(selectedCategory);
          }).length;
        }
        return products.value.length;
      };
    });

    let currentPage = ref(1);
    const sourceData = computed(() => {
      if (selectedCategory.value === "全部") {
        return products.value;
      } else {
        return products.value.filter((item) => {
          return item.category.match(selectedCategory.value);
        });
      }
    });

    const brand = computed(() => {
      let allBrand = new Set();
      sourceData.value.forEach((item) => {
        allBrand.add(item.brand);
      });
      return allBrand;
    });

    const selectedBrand = ref("全部");
    const serchData = computed(() => {
      let newData = [];
      if (selectedBrand.value === "全部") {
        newData = sourceData.value;
      } else {
        newData = sourceData.value.filter((item) => {
          return item.brand.match(selectedBrand.value);
        });
      }

      let { data, pageData } = filter(newData, currentPage.value);
      return {
        products: data,
        pagination: pageData,
        productsLength: newData.length,
      };
    });
    //clickCategory
    const clickCategory = (category) => {
      currentPage.value = 1;
      selectedCategory.value = category;
      selectedBrand.value = "全部";
    };

    const changePage = (page) => {
      currentPage.value = page;
    };
    return {
      products,
      categories,
      serchData,
      selectedCategory,
      selectedBrand,
      counterItems,
      brand,
      clickCategory,
      changePage,
    };
  },
};
</script>
