import "./firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const db = getFirestore();
const categoryRef = collection(db, "categories");

async function fetchCategories() {
  let newArray = [];
  let snapshot = await getDocs(categoryRef);
  snapshot.forEach((item) => {
    newArray.push(item.data());
  });
  return newArray;
}

export { fetchCategories };
