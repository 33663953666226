<template>
  <div class="card h-100">
    <div class="card-header border-0 p-0">
      <div
        class="card-img"
        :style="{ 'background-image': `url(${shape.imgUrl})` }"
        @click="lightBoxOpen"
      ></div>

      <span class="card-text">
        編號 {{ connectNumber(data.number, shape.subNumber) }}</span
      >
    </div>

    <div class="card-body">
      <h6 class="h5 text-center mb-3">
        {{ shape.title }}
      </h6>
      <h6 class="h6 text-center mb-3" v-if="shape.mark">{{ shape.mark }}</h6>
      <router-link to="/contact" class="text-center d-block text-danger"
        >聯絡我們</router-link
      >
    </div>

    <select class="form-control px-2 rounded-0 bg-primary" v-model="shapeId">
      <option value="" selected hidden disabled>查看其他規格</option>
      <template v-for="shape in data.shape" :key="shape.id">
        <option :value="shape.id">{{ shape.title }}</option>
      </template>
    </select>
  </div>
</template>

<script>
import { ref, toRef, inject, computed } from "vue";
export default {
  name: "card",
  props: ["data"],
  setup(props) {
    const data = toRef(props, "data");
    const shapeId = ref("");
    const shape = computed(() => {
      if (!shapeId.value) {
        return data.value.shape[0];
      } else {
        const index = data.value.shape.findIndex(
          (item) => item.id === shapeId.value
        );
        return data.value.shape[index];
      }
    });
    const mitt = inject("mitt");
    const lightBoxOpen = () => {
      mitt.emit("lightBox", shape.value.imgUrl);
    };
    const connectNumber = computed(() => {
      return (number, subNumber) => {
        let str = number;
        if (subNumber) {
          str = `${str}-${subNumber}`;
        }
        return str;
      };
    });
    return {
      lightBoxOpen,
      shapeId,
      shape,
      connectNumber,
    };
  },
};
</script>
