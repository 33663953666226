import "./firebase";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  limit,
  where,
} from "firebase/firestore";

const db = getFirestore();

async function fetchProducts() {
  try {
    const productionCollection = collection(db, "productions");
    const q = query(productionCollection, where("visibility", "==", true));
    let newArray = [];
    let snapshot = await getDocs(q);
    snapshot.forEach((doc) => newArray.push(doc.data()));
    return newArray;
  } catch (error) {
    console.log(error);
  }
}

async function fetchNewProducts() {
  try {
    const productionCollection = collection(db, "productions");
    const q = query(
      productionCollection,
      where("visibility", "==", true),
      limit(10)
    );
    let newArray = [];
    let snapshot = await getDocs(q);
    snapshot.forEach((doc) => newArray.push(doc.data()));
    return newArray;
  } catch (error) {
    console.log(error);
  }
}

export { fetchProducts, fetchNewProducts };
